export const THEME_COLOR = "#0c356a";
export const LOGO_BG_COLOR = "white";
export const TEXT_THEME_COLOR = "#0c356a";
export const FONT_FAMILY = "Volte, sans-serif";

export const GoogleAPI = "AIzaSyDUiB8Ah7Ctycfo0jcZUocDTEt-QsIrae0";

export const CLIENTS = [
  [
    "/images/Swati.jpg",
    "Swati Rawat",
    "School Teacher",
    "I am Investing since last 4 years. Whatever will be the Financial Issues, Laksh Investments has always helped me be it Bank Deposits, Education Loan or Home Loan or be it any issue with ITR related. I am very much satisfied since I am investing.",
  ],
  [
    "/images/Dipesh.jpg",
    "Dipesh Negi",
    "Consultant at Capgemini",
    "I am in touch with Laxman from last 5 years. Laxman Agrawal is very supportive person. He give a quick services whenever I need his assistance. He help me in like Tax Planning and Investing Planning too. I would like to recommend to take his Services to all my friends and relatives.",
  ],
  [
    "/images/Omkar.jpg",
    "Omkar Angchekar",
    "Owner of Clothing Brand Vearom",
    "I was not aware much about Investing. So I have a friend named Laxman. I had started Investments 4 years ago. Initially I was afraid, But slowly slowly when I understood that growth is coming, so I kept on Increasing the amounts as my capacity got increased. And now, I have created a decent Portfolio. So I will advise that whoever wants to Invest, I have friend Laxman, they can do with him.",
  ],
  [
    "/images/Aasiya_Ansari.jpg",
    "Aasiya Ansari",
    "Sr. ORM Executive",
    "One of the best financial advisor you will get, I am his client since a year, the team takes care of each everything related to investments, pf or tax. You should definitely consider laksh investments for your future planning.",
  ],
  [
    "/images/Ankit_Shah.jpg",
    "Ankit Shah",
    "Partner Suburban First Leaders LLP",
    "I can say Laxman at his age is too good and untill now haven’t meet a person like him who take cares of all your minor things also when you are part of his family. I would 100% recommend that people join him and just take his advice, which might help them make better decisions in life and achieve their investment and financial goals.",
  ],
  [
    "/images/Bharat_Iyer.jpg",
    "Bharat Iyer",
    "Sr. Technical Recruiter",
    "Great experience up until now, and I believe it will continue to be. CEO of Laksh has numerous financial advise ready for you, and believe me when I say advise, you will blown away with the knowledge they have. Since working with Laksh I have gained great insights on general as well as my own finances. Looking forward to more such knowledge and wisdom.",
  ],
  [
    "/images/laxmi.jpg",
    "Amit and Laxmi Yadav",
    "IT Professional and Accountant",
    "Very happy to get services of overall financial planning I'm thrilled with the performance of my portfolio over the last year. Your investment strategies have truly paid off🙏🏻",
  ],
  [
    "/images/Ruchi_Pandey.jpg",
    "Ruchi Pandey",
    "Tarot Card Reader",
    "Amazing experience! Laxman has helped me in planning and helping me reach my financial goals♥️ Also he is very proficient at Pf withdrawal and tax planning will definitely recommend him to my friends and family. All the best Laxman! Keep shining💫",
  ],
  [
    "/images/Shifa_Chunawala.jpg",
    "Shifa Chunawala",
    "Dentist",
    "A trustworthy source for your investment. Recently joined the firm and had a great experience.",
  ],
];

export const SERVICES = [
  {
    title: "Mutual Fund SIP",
    description: [
      "A Mutual Fund SIP (Systematic Investment Plan) is a popular investment method that allows individuals to invest small amounts of money at regular intervals in mutual funds. We help you to invest in SIPs, that help mitigate the risk of market volatility by spreading out investments over time, making it an ideal choice for both beginners and seasoned investors. SIPs promote disciplined saving, which is crucial for long-term wealth creation.",
      "The main advantage of SIPs is the power of compounding. When invested for the long term, the returns earned on mutual fund units are reinvested, helping your corpus grow significantly. SIPs also benefit from rupee cost averaging, as you buy more units when prices are low and fewer when prices are high, thus reducing the average cost of your investments.",
      "SIPs are flexible and cater to various financial goals, whether you’re saving for retirement, a child’s education, or building a down payment for a house. We suggest options like equity, debt, or hybrid funds, based on your risk appetite. Over time, SIPs provide higher returns compared to traditional savings accounts or fixed deposits, making them an attractive wealth-building tool.",
    ],
    // "We guide you to pick up the most suitable Mutual Funds as per your risk profile, so that you achieve your goals.",
    imgSrc: "/images/Mutual_Funds.png",
  },
  {
    title: "Retirement Planning",
    description: [
      "We offer retirement planning, which is a critical aspect of financial security, focusing on building a robust corpus that will sustain you after leaving the workforce. We identify your future financial needs and goals based on your current lifestyle, inflation rates, and expected post-retirement expenses. Starting early helps maximize benefits from compounding, ensuring a larger retirement fund.",
      "A diversified investment portfolio is key to successful retirement planning. We consider long-term options like EPF (Employee Provident Fund), PPF (Public Provident Fund), and NPS (National Pension Scheme). These instruments offer relatively lower risk and steady returns over time. Additionally, Mutual Fund SIPs provide the flexibility to invest small amounts regularly, which helps mitigate market risks and can offer higher returns over the long term.",
      "We also regularly monitor your retirement plan, which is essential to stay on track. Inflation, changing lifestyle needs, and unforeseen circumstances may require adjustments to your strategy, which we take care of. We integrate insurance into your plan to protect against health and life risks, ensuring that your retirement fund isn’t depleted due to unforeseen expenses.",
    ],
    // "Want to Become Rich or Don’t want to work Lifelong and Want to Retire Early? Plan your retirement today to become Financially Free!!",
    imgSrc: "/images/Retirement_Planning.png",
  },
  {
    title: "Tax Planning And ITR Filing",
    description: [
      "We offer tax planning, which involves legally reducing your tax liabilities through various financial instruments and exemptions available under the Income Tax Act. We align your investments with sections like 80C, 80D, and 24(b), through which you can save on taxes while growing your wealth. Instruments like ELSS (Equity Linked Savings Scheme), life insurance, PPF, and home loan interest repayment are popular choices for tax saving.",
      "ITR (Income Tax Return) filing is a mandatory annual procedure for individuals and businesses that earn above a certain threshold. We file your ITR accurately and on time, which ensures compliance with the law and helps you avoid penalties. It also allows you to claim refunds for any excess taxes paid during the financial year. Proper tax planning can make the filing process more efficient by organizing your deductions and exemptions.",
      "Moreover, tax planning isn’t just about immediate savings but also long-term financial management. We help in plan your taxes well in advance, which can help you reinvest the savings into productive instruments like mutual funds, health insurance, or tax-saving bonds. This, in turn, contributes to wealth accumulation and financial security for the future.",
    ],
    // "As Law suggests, Tax Evasion is illegal, Tax planning is not. We will help you to reduce your tax outgo by planning it the right way.",
    imgSrc: "/images/Tax_Planning.png",
  },
  {
    title: "Loans",
    description: [
      "We also offer Loans, which act as financial instruments that allow individuals and businesses to borrow money for various purposes like buying a home, or vehicle, or expanding a business. There are different types of loans available, including home loans, personal loans, education loans, and business loans, each serving specific needs. Interest rates and repayment terms vary depending on the type of loan and the borrower’s creditworthiness.",
      "While loans provide immediate financial assistance, managing them requires careful planning, which we have expertise in. It’s essential to choose loans with favorable interest rates and repayment options to avoid falling into a debt trap. We also help you manage money by using tools like EMI calculators, that help you understand the monthly outflow and plan your finances accordingly. Maintaining a good credit score ensures easier access to loans with better terms in the future.",
      "In business, loans are often used to fund expansion, buy equipment, or manage working capital. For personal use, loans should be taken with a clear repayment strategy. Defaulting on loans can damage your credit score and may result in legal or financial penalties. Balancing loan repayment with savings and investments is crucial to maintaining financial stability.",
    ],
    // "As they say, “Home is where the heart is”. We will not only help you get a home loan for your dream home, but will also help you plan how to get rid of it at the earliest.",
    imgSrc: "/images/Loans.png",
  },
  {
    title: "Health Insurance",
    description: [
      "We offer health insurance, which is essential for safeguarding your financial well-being against medical emergencies. With rising healthcare costs, a single hospital visit or surgery can significantly impact your savings. A comprehensive health insurance policy covers hospitalization, treatment, and medication expenses, ensuring that you don’t have to dip into your savings or investments during a medical crisis.",
      "In addition to covering hospitalization costs, we suggest, health insurance plans that may offer benefits like pre- and post-hospitalization cover, maternity benefits, and coverage for critical illnesses. Many plans now also include add-ons like wellness programs, preventive healthcare, and annual checkups, helping policyholders maintain a healthy lifestyle while staying protected financially.",
      "We help you choose the right health insurance, considering factors like coverage amount, premium costs, network hospitals, and claim settlement ratios. It’s crucial to start young as premiums are lower when you are healthy. Family floater plans can also provide coverage for all family members under one policy, making it a cost-effective solution for families.",
    ],
    // "Rising Medical costs and stressful lifestyle can affect your financial plan adversely, unless supplemented by a good health insurance. To know which plan will be most suitable for you, talk to us.",
    imgSrc: "/images/Health_Insurance.png",
  },
  {
    title: "Life Insurance And Term Plan",
    description: [
      "We offer services of Life insurance, particularly a term plan, which is a vital financial tool that provides security to your family in the event of your unexpected demise. A term insurance plan offers a death benefit to the nominee, if the policyholder passes away during the term of the policy, ensuring that your family remains financially stable despite the loss of your income.",
      "The primary advantage of a term plan is its affordability; it provides high coverage for relatively low premiums compared to other types of life insurance. Term insurance is typically tailored to cover a specific period—10, 20, or 30 years—depending on your financial obligations. For example, if you have young children, we might opt for a plan that provides coverage until you are financially independent, or if you have a home loan, the plan’s duration could be aligned with the loan repayment schedule, as per us.",
      "While the policy doesn’t have a maturity benefit, we ensure that your family is financially covered during critical years. We keep in consideration your needs and hence help you choose a sum assured that adequately covers your family’s future needs, including debts, daily living expenses, and long-term goals like education or marriage.",
      "Term plans also come with additional riders, such as critical illness or accidental death coverage, offering comprehensive protection at a marginal increase in premium. We integrate a term plan into your financial strategy which can ensure you, peace of mind knowing that your loved ones are protected from financial strain.",
    ],
    // "No financial plan can ever be completed without life insurance. If insurance is missing from your portfolio and / or you are looking at knowing, how much should be your insurance amount, contact us.",
    imgSrc: "/images/Term_Insurance.png",
  },
  {
    title: "Child Education Planning",
    description: [
      "We offer child education planning, which is a crucial aspect of financial planning, aimed at ensuring that your children’s educational aspirations are not hindered by rising costs. With education expenses increasing at a rapid pace, it is important to start saving early. By estimating the future costs of schooling, higher education, and potential overseas studies, parents can begin building an education fund through systematic investments. The earlier you start, the greater the benefit from compounding.",
      "We offer Investment options like Mutual Fund SIPs, education-specific insurance plans, and Sukanya Samriddhi Yojana (for daughters), and other targeted solutions for education savings. SIPs in equity funds, in particular, provide good long-term growth potential, helping to accumulate a significant corpus over time. On the other hand, education-specific insurance plans combine the benefits of savings and insurance, providing a lump sum for educational expenses in case of the policyholder’s demise.",
      "We regularly review your child’s education plan, as costs can fluctuate with inflation and changing aspirations. Balancing high-growth investments with low-risk instruments such as debt funds or fixed deposits is advisable to reduce risk as the goal approaches. Early and consistent planning ensures that when the time comes, your child’s educational dreams can be fulfilled without financial strain.",
    ],
    // "Are you afraid of rising education cost for children these days? Don’t worry. We can help you plan for your Children’s Education.",
    imgSrc: "/images/Child_Education_Planning.png",
  },
  {
    title: "PF Withdrawal",
    description: [
      "We also offer a Provident Fund (PF) withdrawal process, where employees can access their accumulated retirement savings from the Employee Provident Fund (EPF) account. While PF is intended to serve as a retirement fund, we help you in partial withdrawals, that are allowed for specific purposes like buying a home, education, or medical emergencies. However, the full withdrawal is usually permitted only after retirement or in case of unemployment for a specific period.",
      "The EPF scheme offers a stable interest rate and is considered one of the safest ways to build a retirement corpus. Each month, employees contribute 12% of their basic salary to the EPF, with an equal contribution made by their employer. The interest earned on these contributions is compounded annually, making it a lucrative long-term investment.",
      "When making the PF withdrawals, we also keep in consideration the tax implications. While PF withdrawals after five years of continuous service are tax-free, early withdrawals may attract taxes. Hence, we keep these rules in mind and withdraw only when absolutely necessary, ensuring that your retirement fund remains intact.",
    ],
    // "Don’t want to run pillar to post for your PF amount? We can help you with a smooth withdrawal seating at home.",
    imgSrc: "/images/PF_Withdrawal.png",
  },
  {
    title: "Tax-Saving Bonds And FDs",
    description: [
      "We also offer services like Tax-saving bonds and fixed deposits (FDs), which are low-risk investment options that offer the dual benefit of wealth growth and tax savings. Under Section 80C of the Income Tax Act, individuals can invest in tax-saving FDs or government bonds and claim deductions of up to ₹1.5 lakh annually.",
      "We offer these instruments, as they are ideal for conservative investors looking for a fixed return with minimal risk. Tax-saving FDs typically have a five-year lock-in period and offer interest rates ranging from 5-7%, depending on the bank and market conditions. Interest earned on these FDs is taxable, but the principal investment qualifies for tax deductions. On the other hand, tax-saving bonds like the ones issued by infrastructure companies or government institutions usually have a longer tenure, but they provide tax-free interest.",
      "Investing in these instruments ensures capital protection and stable returns, making them an essential part of a diversified portfolio. They are particularly suitable for retirees or those with a low-risk appetite who want to save taxes while preserving capital.",
    ],
    // "Sold a Property and now worried for Capital Gains? We will help you minimize your tax outgo with the help of 54EC bonds.",
    imgSrc: "/images/Tax_Saving_Bonds.png",
  },
  {
    title: "Wealth Planning",
    description: [
      "We offer services of wealth planning, which involves creating a comprehensive strategy to manage, grow, and protect your financial assets. It goes beyond saving and investing, addressing estate planning, tax efficiency, and risk management. The primary goal of us is to ensure financial security for yourself and your loved ones while maximizing your wealth’s potential for growth.",
      "Our service involves diversifying your investments across asset classes such as stocks, bonds, real estate, and mutual funds. We help you balance the high-risk and low-risk investments, which helps you mitigate market volatility and ensure steady growth over time. We also focus on tax-efficient strategies, helping you optimize returns by reducing tax liabilities.",
      "Beyond investment management, wealth planning includes estate planning, where legal structures such as wills and trusts are created to ensure the smooth transfer of assets to beneficiaries. Regular reviews of your wealth plan are essential to align it with changes in your financial situation, market conditions, and life goals, ensuring long-term financial stability.",
    ],
    // "Are you afraid of rising education cost for children these days? Don’t worry. We can help you plan for your Children’s Education.",
    imgSrc: "/images/Wealth_Planning.png",
  },
  {
    title: "Business Coaching",
    description: [
      "We provide entrepreneurs and professionals with the guidance they need to grow their businesses and enhance their leadership skills. We also help clients clarify their vision, set achievable goals, and overcome challenges through strategic planning. Whether you're a startup founder or running an established business, we provide personalized support to navigate complex business landscapes.",
      "We work on various aspects such as financial planning, operational efficiency, and personal development. Helping you identify strengths, weaknesses, opportunities, and threats (SWOT analysis) enables you to make informed decisions. We also assist in developing leadership skills and building a team that shares your vision for the company’s growth.",
      "In the long run, business coaching improves both profitability and sustainability. We provide actionable insights to scale your business, manage resources more efficiently, and stay competitive. Digital brand building and marketing strategies are often part of the coaching process, helping companies reach a wider audience and establish a strong online presence.",
    ],
    // "Don’t want to run pillar to post for your PF amount? We can help you with a smooth withdrawal seating at home.",
    imgSrc: "/images/Business_Coaching.png",
  },
  {
    title: "Digital Brand Building",
    description: [
      "We also offer services like Digital brand building, which is essential in today’s online-driven world, especially for businesses and professionals looking to establish a strong market presence. We create and maintain a brand identity that resonates with your target audience across digital platforms such as social media, websites, and blogs. By cultivating a clear, consistent, and engaging online presence, you can differentiate yourselves from competitors and foster long-term relationships with customers.",
      "Initially, we start the process by defining your brand’s core message, values, and visual identity, which should be consistently reflected in all digital touchpoints. We prioritize Social media platforms like Instagram, LinkedIn, and Twitter, which are vital for engaging with your audience, sharing content, and building a community around your brand. Content marketing, including blogs, videos, and webinars, plays a significant role in positioning yourself as an authority in your industry and increasing visibility. We also offer SEO (Search Engine Optimization) and paid digital marketing strategies, such as Google Ads or social media advertising, to help enhance brand awareness and drive traffic to your website or platform.",
      "Our main motive behind digital brand building is engagement—interacting with your audience, responding to feedback, and creating a meaningful online relationship. Over time, a well-executed digital brand strategy leads to increased trust, customer loyalty, and business growth.",
    ],
    // "Sold a Property and now worried for Capital Gains? We will help you minimize your tax outgo with the help of 54EC bonds.",
    imgSrc: "/images/Digital_Brand_Building.png",
  },
];

export const ADDRESS = `404, Shri Sai Swami Apt, N.R. Nagar, Station Road, Diva (west), Thane - 400612, India`;

export const EMAILS = [
  "ceo@lakshinvestments.in",
  "ceo.lakshinvestments@gmail.com",
];

export const MOBILE_NUMBERS = ["+91 97 6929 8042", "+91 97 6910 1917"];

export const SESSIONS = [
  {
    place: "B.K Birla College, Kalyan",
    images: [
      "/images/bk1.jpg",
      "/images/bk2.jpg",
      "/images/bk3.jpg",
      "/images/bk4.jpg",
    ],
  },
  {
    place: "Pillai College, Panvel",
    images: [
      "/images/pillai1.jpg",
      "/images/pillai2.jpg",
      "/images/pillai3.jpg",
    ],
  },
  {
    place: "SIES College, Nerul",
    images: [
      "/images/SIES1.jpg",
      "/images/SIES4.jpg",
      "/images/SIES2.jpg",
      "/images/SIES5.jpg",
      "/images/SIES3.jpg",
    ],
  },
];

export const ABOUT_IMAGES = [
  "/images/about1.jpg",
  "/images/about2.jpg",
  "/images/about3.jpg",
  "/images/about4.jpg",
  "/images/about5.jpg",
  "/images/about6.jpg",
  "/images/about7.jpg",
  "/images/about8.jpg",
  "/images/about9.jpg",
  "/images/about10.jpg",
  "/images/about11.jpg",
  "/images/about12.jpg",
  "/images/about13.jpg",
  "/images/about14.jpg",
  "/images/about15.jpg",
  "/images/about16.jpg",
];

export const AWARDS = [
  "/images/Certificate1.jpg",
  "/images/Certificate2.jpg",
  "/images/Certificate3.jpg",
  "/images/Certificate4.jpg",
  "/images/Certificate5.jpg",
  "/images/Certificate6.jpg",
  "/images/Certificate7.jpg",
  "/images/Certificate8.jpg",
];

export const ClientsData = {
  labels: ["Clients"],
  datasets: [
    {
      label: "MH",
      data: [135],
      tooltip: "Maharashtra",
    },
    {
      label: "KA",
      data: [10],
      tooltip: "Karnataka",
    },
    {
      label: "GJ",
      data: [4],
      tooltip: "Gujarat",
    },
    {
      label: "JH",
      data: [3],
      tooltip: "Jharkhand",
    },
    {
      label: "U.P",
      data: [1],
      tooltip: "Uttar Pradesh",
    },
    {
      label: "CG",
      data: [1],
      tooltip: "Chhattisgarh",
    },
    {
      label: "TG",
      data: [2],
      tooltip: "Telangana",
    },
    {
      label: "KL",
      data: [2],
      tooltip: "Kerala",
    },
    {
      label: "QA",
      data: [1],
      tooltip: "Qatar",
    },
    {
      label: "OM",
      data: [1],
      tooltip: "Oman",
    },
    {
      label: "Lan",
      data: [1],
      tooltip: "Landon",
    },
    {
      label: "AP",
      data: [2],
      tooltip: "Andhra Pradesh",
    },
  ],
  tooltips: [
    "Maharashtra",
    "Karnataka",
    "Gujarat",
    "Jharkhand",
    "Uttar Pradesh",
    "Chhattisgarh",
    "Telangana",
    "Kerala",
    "Qatar",
    "Oman",
    "Landon",
    "Andhra Pradesh",
  ],
};
