import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY } from "../../Constant";
import { UseInView } from "../../Utils";

export const CeoMiddle: React.FC = () => {
  const { isInView, elementRef } = UseInView();

  return (
    <Box ref={elementRef}>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        After completing more than 5 years in Financial Markets, He formed his
        own venture named Laksh Investments where the Primary focus is on
        Planning based on Lakshya (Goals).
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        He can guide on Tax Planning and also can help in filling ITR 1, ITR 2,
        ITR 3 and ITR 4.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        “His Expertise area are Personal Financial Planning where he can guide
        from basics to make and reach the Financial Goals within the stipulated
        time." He can also do the Expert Research and can give his findings
        accordingly in the Insurance matters.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        He has been Invited by many educational institutions to take sessions
        like from BVP Sunrise Dental College, Kharghar, Rotaract Club of Anand
        Institute of higher technology and SDNB Vaishnav College.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        He has also been invited by Pratham Finance, Thane for the Employees
        Training Session. He has also been featured on Spector Prince Youtube
        Channel to take Session on Personal Finance.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        He is also a Rotaractor from last 3 Years. Currently, The International
        Service Director and Treasurer from RC Thane Central He won the Best
        Director Award in his 1st Year when he became Professional Development
        Director.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        His Quotes, Articles and Letters have got featured many times on
        different Club Pages and their Blogs like Rotaract Club of Ambernath,
        Rotaract Club of Ulhasnagar Sapna Garden, Rotaract Club of BVP Sunrise,
        Rotaract Club of Thane East etc.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        In December 2020, He was Invited by a Club to give Speaker Session on
        Wealth Creation on the topic of "Apna Sapna Money Money". He also also
        Won the Prize for Writing Article on Finance Budget 2020-21. He also has
        got chance to Participate in Creative Writing and Extempore at the
        International Level.
      </Text>
      <Text
        mb={4}
        textAlign="justify"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        He loves Economics and Value Investing. He wants to become Finance
        Minister. His dream is to get Featured on Wikipedia. His hobbies are
        Travelling, Investing, Making New Friends and Exploring New-New Ideas to
        grow and inspire others to excel.
      </Text>
    </Box>
  );
};

export default CeoMiddle;
