import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Grid,
  IconButton,
  Link,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FONT_FAMILY, GoogleAPI, THEME_COLOR } from "../../Constant";
import { BsYoutube } from "react-icons/bs";
import { UseInView } from "../../Utils";

export const VideoGrid: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  const [videos, setVideos] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;

  const API_KEY = GoogleAPI;
  const CHANNEL_ID = "UCUxjLR2ip0xuYKJ-xc28tRQ";

  const youtubeVideos = [
    "https://www.youtube.com/embed/wDC4J31MkCY?si=xHw35jMhW-Vjcji7",
    "https://www.youtube.com/embed/OkwAE8lz88Q?si=vnm9yVoGhLF-wccS",
    "https://www.youtube.com/embed/DfkIIq9gbbE?si=1tqVz5b9de1AE0aT",
    "https://www.youtube.com/embed/WdIV7WqF36o?si=zaEgnQKgWw9JUbNE",
    "https://www.youtube.com/embed/JNqenfTC3Nc?si=MxgXD8YI_QaS7bBf",
    "https://www.youtube.com/embed/vn9KiKddFp0?si=pvHlHUzXFflPTrGI",
  ];

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=12`
      );
      const data = await response.json();
      setVideos(data.items);
    };

    fetchVideos();
  }, [API_KEY, CHANNEL_ID]);

  // Calculate the index range for the current page
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentVideos = videos?.slice(indexOfFirstCard, indexOfLastCard);

  // Calculate total pages
  const totalPages = Math.ceil(videos?.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box ref={elementRef}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={4}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        {currentVideos?.length > 0
          ? currentVideos?.map((video) => (
              <Box
                key={video.id.videoId}
                borderWidth="2px"
                boxShadow={"lg"}
                //   padding={2}
                overflow="hidden"
              >
                <Link
                  href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                  isExternal
                  textDecoration={"none"}
                  _hover={{
                    textDecoration: "none",
                    color: `${THEME_COLOR}`,
                  }}
                  fontFamily={FONT_FAMILY}
                >
                  <Box position={"relative"}>
                    <Image
                      src={video.snippet.thumbnails.high.url}
                      alt={video.snippet.title}
                      objectFit="cover"
                      width={"100%"}
                    />
                    <Flex
                      position="absolute"
                      bottom="-5%"
                      right="-10%"
                      transform="translate(-50%, -50%)"
                      bg="rgba(0, 0, 0, 0.5)"
                      pl={3}
                      pr={3}
                      borderRadius="5px"
                      alignItems={"center"}
                    >
                      <Text
                        color={"red"}
                        fontSize={"2rem"}
                        fontFamily={FONT_FAMILY}
                      >
                        <BsYoutube />
                      </Text>
                      <Text
                        color={"white"}
                        ml={"2px"}
                        _hover={{ fontWeight: "blod" }}
                        fontFamily={FONT_FAMILY}
                      >
                        YouTube
                      </Text>
                    </Flex>
                  </Box>
                  <Box>
                    <Text p={4} fontFamily={FONT_FAMILY}>
                      {video.snippet.title}
                    </Text>
                  </Box>
                </Link>
              </Box>
            ))
          : youtubeVideos.map((youtubeVideo, index) => (
              <Box
                key={index.toString() + "youtubevideo"}
                borderWidth="2px"
                boxShadow={"lg"}
                padding={2}
                overflow="hidden"
              >
                <iframe
                  width={"100%"}
                  height="315"
                  src={`${youtubeVideo}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </Box>
            ))}
      </Grid>

      {/* Pagination Controls */}
      {currentVideos?.length > 0 && (
        <Flex justify="center" mt={6} alignItems={"center"}>
          <IconButton
            aria-label="Previous"
            icon={<ArrowBackIcon />}
            onClick={handlePrevPage}
            isDisabled={currentPage === 1}
            borderRadius="full"
            bg="transparent"
            border={`2px solid ${THEME_COLOR}`}
            color={`${THEME_COLOR}`}
            _hover={{ bg: "white", color: "black" }}
          />
          <Text
            ml={2}
            mr={2}
            fontFamily={FONT_FAMILY}
          >{`${currentPage} / ${totalPages}`}</Text>
          {/* <Button
      onClick={handleNextPage}
      isDisabled={currentPage === totalPages}
      ml={2}
    >
      Next */}
          <IconButton
            aria-label="Next"
            icon={<ArrowForwardIcon />}
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}
            borderRadius="full"
            bg="transparent"
            border={`2px solid ${THEME_COLOR}`}
            color={`${THEME_COLOR}`}
            _hover={{ bg: "white", color: "black" }}
            fontFamily={FONT_FAMILY}
          />
          {/* </Button> */}
        </Flex>
      )}
    </Box>
  );
};

export default VideoGrid;
