import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { BsCheckCircle } from "react-icons/bs";
import { UseInView } from "../../Utils";

export const CeoTop: React.FC = () => {
  const { isInView, elementRef } = UseInView();

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      alignItems="center"
      gap={6}
      ref={elementRef}
    >
      <Box
        w={{ base: "100%", md: "30%" }}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Box
          textAlign="center"
          // className="frame"
          height={"35rem"}
          borderWidth="2px"
          borderColor="rgba(238, 238, 238, 0.7)"
          borderBottomColor="rgba(255, 255, 255, 0.9)"
          borderLeftColor="rgba(238, 238, 238, 0.7)"
          borderRightColor="rgba(238, 238, 238, 0.7)"
          borderTopColor="rgba(204, 204, 204, 0.8)"
          borderRadius="md"
          boxShadow="inset 0 0 5px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.25)"
          bg="#0c356a"
          color="white"
          p={2}
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            bottom: "-2vmin",
            left: "-2vmin",
            right: "-2vmin",
            top: "-2vmin",
            borderRadius: "2px",
            boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.25)",
          }}
          _after={{
            content: '""',
            position: "absolute",
            bottom: "-2.5vmin",
            left: "-2.5vmin",
            right: "-2.5vmin",
            top: "-2.5vmin",
            borderRadius: "2px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Image
            src="/images/ceo.jpeg"
            alt="CEO"
            height="75%" // Adjust the height as needed
            width="100%" // Ensure the image fills the width of the parent container
            objectFit="cover" // Cover the container while preserving aspect ratio
            borderWidth="2px"
            borderColor="rgba(238, 238, 238, 0.7)"
            borderBottomColor="rgba(255, 255, 255, 0.9)"
            borderLeftColor="rgba(238, 238, 238, 0.7)"
            borderRightColor="rgba(238, 238, 238, 0.7)"
            borderTopColor="rgba(204, 204, 204, 0.8)"
          />
          <VStack spacing={2} mt={4}>
            <Heading size="md" fontFamily={FONT_FAMILY}>
              Laxman Agrawal <br />
              (BMS Finance, CFA Investment Foundations, FYPA, CRA) <br />
              CEO, Laksh Investments
            </Heading>
          </VStack>
        </Box>
      </Box>

      <Box
        w={{ base: "100%", md: "70%" }}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Box
          className="right-para"
          pl={{ base: 0, md: "30px" }}
          textAlign={"justify"}
        >
          <Heading
            as="h5"
            size="lg"
            mb={4}
            color={THEME_COLOR}
            fontFamily={FONT_FAMILY}
          >
            Welcome from the CEO
          </Heading>

          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            Laxman Agrawal (aka the Next Warren Buffett) is the CEO with Laksh
            Investments. He has completed his Graduation in the year 2018 in
            Bachelor's of Management Studies with Specialization in Finance (BMS
            Finance) Field from MCC Mulund College of Commerce, Mumbai, India.
          </Text>
          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            He has earned the CFA Institute Investment Foundation Certificate.
            He has also completed International Certification in Financial
            Planning for Young Adults (FPYA) developed in Partnership with CFP
            Board by Illinois University.
          </Text>
          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            In 2022, he completed his CRA Certification i.e. Chartered
            Retirement Advisor.
          </Text>
          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            He is a Blogger and writes on various Topics like Personal Finance,
            Motivational Topics, Life Skills, Taxation, etc. Currently his
            Article viewership is in more than 36 Countries Including European
            Union.
          </Text>
          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            His mission is to Make Youngsters Financially Free. He believes that
            Everyone should be Rich in Four aspects:
          </Text>
          <List spacing={3} mb={4}>
            <ListItem fontWeight="bold">
              {" "}
              <HStack spacing={2} fontFamily={FONT_FAMILY}>
                <BsCheckCircle />
                <span>Rich in Nature</span>
              </HStack>
            </ListItem>
            <ListItem fontWeight="bold">
              {" "}
              <HStack spacing={2} fontFamily={FONT_FAMILY}>
                <BsCheckCircle />
                <span>Rich in Relationship</span>
              </HStack>
            </ListItem>
            <ListItem fontWeight="bold">
              <HStack spacing={2} fontFamily={FONT_FAMILY}>
                <BsCheckCircle />
                <span>Rich in Knowledge</span>
              </HStack>
            </ListItem>
            <ListItem fontWeight="bold">
              <HStack spacing={2} fontFamily={FONT_FAMILY}>
                <BsCheckCircle />
                <span>Rich in Money</span>
              </HStack>
            </ListItem>
          </List>
          <Text mb={4} textAlign="justify" fontFamily={FONT_FAMILY}>
            He has been Invited for “Financial Advisor Awards: 2018–19”. He
            keeps on meeting with the CEOs, CFOs, CIOs, COOs, Chairman, Fund
            Managers and many experts in Mutual Fund Industry and Capital
            Markets on regular basis.
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default CeoTop;
