import {
  Container,
  Flex,
  Text,
  Image,
  Box,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, SESSIONS, THEME_COLOR } from "../../Constant";

export const Sessions: React.FC = () => {
  const isDesktop = useBreakpointValue({ base: false, md: false, lg: true });

  return (
    <Container maxW="100vw" pt={{ base: 10, md: 10 }} px={0}>
      {SESSIONS.map((session, index) => {
        return (
          <Flex
            key={index.toString() + "session"}
            alignItems={"center"}
            direction={{
              base: "column",
              md: index % 2 === 0 ? "row" : "row-reverse",
            }}
            justifyContent={"space-between"}
            backgroundImage={
              index % 2 === 0
                ? `linear-gradient(to right, white, ${THEME_COLOR})`
                : `linear-gradient(to left, white, ${THEME_COLOR})`
            }
          >
            {/* Left Section */}
            <Box
              w={{ base: "100%", md: "30%" }}
              pt={isDesktop ? "" : 5}
              className={"animate__animated animate__zoomIn"}
            >
              <Text
                as={"h5"}
                fontSize={"1.5rem"}
                fontFamily={FONT_FAMILY}
                color={isDesktop ? THEME_COLOR : "white"}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                {session.place}
              </Text>
            </Box>

            {/* Right Section - Image Grid */}
            <Box
              w={{ base: "100%", md: "70%" }}
              py={5}
              className={"animate__animated animate__zoomIn"}
            >
              {isDesktop ? (
                <Grid
                  sx={{
                    margin: "auto",
                    maxWidth: "100%",
                    display: "grid",
                    gridTemplateColumns: {
                      base: "repeat(2, 1fr)",
                      md: "repeat(4, 1fr)",
                      lg: "repeat(8, 1fr)",
                    },
                    gridAutoRows: { base: "150px", md: "200px" },
                    paddingBottom: "50px",
                  }}
                  columnGap={5}
                >
                  {session.images.map((src: string, imgIndex: number) => (
                    <Box
                      key={imgIndex.toString() + "image"}
                      ml={12}
                      sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        height: "250px",
                        backgroundColor: "white",
                        clipPath:
                          "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
                        gridColumn: (() => {
                          if (imgIndex + 1 === 1)
                            return { base: "1 / span 2", md: "2 / span 2" };
                          if (imgIndex + 1 === 2)
                            return { base: "2 / span 2", md: "4 / span 2" };
                          if (imgIndex + 1 === 3)
                            return { base: "1 / span 2", md: "6 / span 2" };
                          if (imgIndex + 1 === 4)
                            return { base: "1 / span 2", md: "1 / span 2" };
                          if (imgIndex + 1 === 5)
                            return { base: "2 / span 2", md: "3 / span 2" };
                          if (imgIndex + 1 === 6)
                            return { base: "1 / span 2", md: "5 / span 2" };
                          if (imgIndex + 1 === 7)
                            return { base: "2 / span 2", md: "7 / span 2" };
                          if (imgIndex + 1 === 8)
                            return { base: "1 / span 2", md: "2 / span 2" };
                          if (imgIndex + 1 === 9)
                            return { base: "2 / span 2", md: "4 / span 2" };
                          if (imgIndex + 1 === 10)
                            return { base: "1 / span 2", md: "6 / span 2" };
                          return "auto";
                        })(),
                        gridRow: (() => {
                          if (imgIndex + 1 <= 3) return 1;
                          if (imgIndex + 1 <= 7) return 2;
                          return 3;
                        })(),
                      }}
                    >
                      <Image
                        src={src}
                        sx={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          objectFit: "cover",
                        }}
                        boxShadow={"0px 4px 4px 0px rgba(0,0,0,0.8)"}
                      />
                    </Box>
                  ))}
                </Grid>
              ) : (
                <Grid
                  sx={{
                    margin: "auto",
                    maxWidth: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "14px",
                    paddingBottom: "50px",
                  }}
                >
                  {session.images.map((src: string, imgIndex: number) => (
                    <Box
                      key={imgIndex.toString() + "image"}
                      sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        height: "150px",
                        backgroundColor: "white",
                      }}
                    >
                      <Image
                        src={src}
                        alt={`Image ${imgIndex + 1}`}
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ))}
                </Grid>
              )}
            </Box>
          </Flex>
        );
      })}
    </Container>
  );
};

export default Sessions;
