import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { UseInView } from "../../Utils";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

export const Description: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  return (
    <Box
      bg={THEME_COLOR}
      backgroundImage="/images/home_back.jpg"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundAttachment="fixed"
      ref={elementRef}
    >
      <Box bg={"rgba(0,0,0,0.5)"} py={{ base: 10, md: 32 }}>
        <Flex
          align="center"
          direction={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "left" }}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Box
            flex="2"
            mb={{ base: 4, md: 0 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <FaQuoteLeft color="white" size={"50%"} />
          </Box>
          <Box flex="8" px={{ base: 0, md: 4 }}>
            <Text
              fontStyle="italic"
              fontSize={{ base: "md", md: "1.5rem" }}
              fontFamily={FONT_FAMILY}
              color="white"
              textAlign={"center"}
            >
              "Laksh Investments is a professional financial service provider
              with a proven track record. Laksh Investments helps you to reach
              your desire goals with a complete financial planning for you.
              Laksh Investments is a one-stop solution for all your needs."
            </Text>
          </Box>
          <Box
            flex="2"
            mt={{ base: 4, md: 0 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <FaQuoteRight color="white" size={"50%"} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Description;
