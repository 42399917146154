import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { FONT_FAMILY, SERVICES, THEME_COLOR } from "../../Constant";
import { useNavigate } from "react-router-dom";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { UseInView } from "../../Utils";

export const Services: React.FC = () => {
  const navigate = useNavigate();
  const { isInView, elementRef } = UseInView();

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;

  const handleViewDetails = (title: string) => {
    navigate(`/service/${title}`);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentServices = SERVICES?.slice(indexOfFirstCard, indexOfLastCard);

  // Calculate total pages
  const totalPages = Math.ceil(SERVICES?.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box ref={elementRef}>
      <Heading
        as="h1"
        textAlign="center"
        fontFamily={FONT_FAMILY}
        color={THEME_COLOR}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        Services
      </Heading>
      <Flex
        mb={4}
        justifyContent={"center"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Image
          src={"/images/Testimonial.png"}
          filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
        />
      </Flex>
      <Flex direction="row" wrap="wrap" gap={4} justify="space-between" mt={8}>
        {currentServices.map((service, index) => (
          <Flex
            key={index.toString() + "service"}
            width={{ base: "100%", md: "30%" }} // Adjust width for 3 cards in a row
            mb={4}
            pb={4}
            borderWidth="1px"
            // borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            height="500px" // Fixed height for each card
            flexDirection="column"
            className={isInView ? "animate__animated animate__zoomIn" : ""}
          >
            <Image
              src={service.imgSrc}
              alt={service.title}
              width="100%"
              height="65%" // Adjusted to fit the card height
              // objectFit="cover" // Ensures the image covers the area without distortion
            />
            <Box p={4} flex="1">
              <Heading
                as="h6"
                size="md"
                mb={2}
                color={THEME_COLOR}
                fontFamily={FONT_FAMILY}
              >
                {service.title}
              </Heading>
              <Text fontFamily={FONT_FAMILY} noOfLines={2}>
                {service.description[0]}
              </Text>
            </Box>
            <Flex alignItems={"center"} justifyContent={"center"} mb={2}>
              <Button
                type="submit"
                letterSpacing={"1px"}
                gap={"5px"}
                bg={"transparent"}
                border={`2px solid ${THEME_COLOR}`}
                borderRadius={"50px 50px"}
                color={THEME_COLOR}
                _hover={{ background: THEME_COLOR, color: "white" }}
                w="8rem"
                onClick={() => handleViewDetails(service.title)}
                fontFamily={FONT_FAMILY}
                fontSize={"14px"}
              >
                Know More <ArrowRightIcon />
              </Button>
            </Flex>
          </Flex>
        ))}
      </Flex>

      {currentServices?.length > 0 && (
        <Flex justify="center" mt={6} alignItems={"center"}>
          <IconButton
            aria-label="Previous"
            icon={<ArrowBackIcon />}
            onClick={handlePrevPage}
            isDisabled={currentPage === 1}
            borderRadius="full"
            bg="transparent"
            border={`2px solid ${THEME_COLOR}`}
            color={`${THEME_COLOR}`}
            _hover={{ bg: "white", color: "black" }}
            fontFamily={FONT_FAMILY}
          />
          <Text
            ml={2}
            mr={2}
            fontFamily={FONT_FAMILY}
          >{`${currentPage} / ${totalPages}`}</Text>
          <IconButton
            aria-label="Next"
            icon={<ArrowForwardIcon />}
            onClick={handleNextPage}
            isDisabled={currentPage === totalPages}
            borderRadius="full"
            bg="transparent"
            border={`2px solid ${THEME_COLOR}`}
            color={`${THEME_COLOR}`}
            _hover={{ bg: "white", color: "black" }}
          />
        </Flex>
      )}
    </Box>
  );
};

export default Services;
