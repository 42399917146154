import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import axios from "axios";

export const ContactUsForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const companyName = {
    company_name: "Laksh Investments",
  };

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      ...companyName,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setIsSuccess(false);
    setIsError(false);

    try {
      // Replace 'your-server-endpoint' with the actual PHP server endpoint URL
      const response = await axios.post(
        "https://lakshinvestments.in/mail/send-email.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      setResponseMessage("Form submitted successfully!");
      setIsSuccess(true);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setResponseMessage("Error sending email");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      mx="auto"
      p="6"
      bg={"transparent"}
      boxShadow={{ base: "none", md: "0px 4px 4px 2px rgba(0,0,0,0.4)" }}
      fontFamily={FONT_FAMILY}
    >
      {/* Success Alert */}
      {isSuccess && (
        <Alert
          status="success"
          variant="subtle"
          borderRadius="md"
          mb={"2%"}
          fontFamily={FONT_FAMILY}
        >
          <AlertIcon />
          {responseMessage}
        </Alert>
      )}

      {/* Error Alert */}
      {isError && (
        <Alert
          status="error"
          variant="subtle"
          borderRadius="md"
          mb={"2%"}
          fontFamily={FONT_FAMILY}
        >
          <AlertIcon />
          {responseMessage}
        </Alert>
      )}

      {/* Form */}
      <Box as="form" onSubmit={handleSubmit} color={THEME_COLOR}>
        <FormControl id="name" mb={4} isRequired>
          <FormLabel fontFamily={FONT_FAMILY}>Name</FormLabel>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            fontFamily={FONT_FAMILY}
          />
        </FormControl>

        <FormControl id="email" mb={4} isRequired>
          <FormLabel fontFamily={FONT_FAMILY}>Email</FormLabel>
          <Input
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            fontFamily={FONT_FAMILY}
          />
        </FormControl>

        <FormControl id="message" mb={4} isRequired>
          <FormLabel fontFamily={FONT_FAMILY}>Message</FormLabel>
          <Textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </FormControl>

        <Flex justify="center" mt="4">
          <Button
            type="submit"
            letterSpacing={"1px"}
            gap={"5px"}
            bg={"transparent"}
            border={`2px solid ${THEME_COLOR}`}
            borderRadius={"50px 50px"}
            color={THEME_COLOR}
            _hover={{ background: THEME_COLOR, color: "white" }}
            w="8rem"
            fontFamily={FONT_FAMILY}
            fontSize={"14px"}
          >
            {loading ? <Spinner size="md" /> : "Submit"}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
