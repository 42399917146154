import {
  Box,
  Text,
  Flex,
  IconButton,
  Stack,
  useDisclosure,
  Image,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Button,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import {
  THEME_COLOR,
  TEXT_THEME_COLOR,
  SERVICES,
  FONT_FAMILY,
} from "../Constant";

export const Navbar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onServiceClick = () => {
    onClose();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navLinks = () => {
    return (
      <>
        {isMobile ? (
          <>
            {/* Mobile View - Hamburger Icon */}
            <IconButton
              icon={<HamburgerIcon />}
              aria-label="Open Menu"
              onClick={onOpen}
              variant="outline"
            />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  {/* Drawer Navigation */}
                  <Flex direction="column" gap={4} mt={8}>
                    <Text
                      as={NavLink}
                      to="/home"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      Home
                    </Text>
                    <Menu>
                      <MenuButton
                        as={Text}
                        onClick={toggleDropdown}
                        fontFamily={FONT_FAMILY}
                        fontWeight="bold"
                        _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                      >
                        <Flex>
                          Services
                          <Box as="span" ml={2}>
                            {isDropdownOpen ? (
                              <ChevronUpIcon />
                            ) : (
                              <ChevronDownIcon />
                            )}
                          </Box>
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        <Flex direction="column">
                          {SERVICES.map((service, index) => (
                            <MenuItem
                              key={index.toString() + "service"}
                              as={NavLink}
                              to={`/service/${service.title}`}
                              onClick={onClose}
                              fontFamily={FONT_FAMILY}
                            >
                              {service.title}
                            </MenuItem>
                          ))}
                        </Flex>
                      </MenuList>
                    </Menu>
                    <Text
                      as={NavLink}
                      to="/about"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      About
                    </Text>
                    <Text
                      as={NavLink}
                      to="/ceo"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      CEO
                    </Text>
                    <Text
                      as={NavLink}
                      to="/sessions"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      Sessions
                    </Text>
                    <Text
                      as={NavLink}
                      to="https://asklaxman.wordpress.com/"
                      onClick={onClose}
                      target="__blank"
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      Blog
                    </Text>
                    <Text
                      as={NavLink}
                      to="/vlog"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      Vlog
                    </Text>
                    <Text
                      as={NavLink}
                      to="/contact"
                      onClick={onClose}
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                    >
                      Contact
                    </Text>
                    <Button
                      as={NavLink}
                      to={"https://www.ifaplanet.com/form_login.php"}
                      target="__blank"
                      fontFamily={FONT_FAMILY}
                      fontWeight="bold"
                      fontSize={"14px"}
                      mr={10}
                      letterSpacing={"1px"}
                      // gap={"2px"}
                      bg={"transparent"}
                      border={`2px solid ${THEME_COLOR}`}
                      borderRadius={"50px 50px"}
                      color={THEME_COLOR}
                      _hover={{ background: THEME_COLOR, color: "white" }}
                    >
                      Client Login
                    </Button>
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        ) : (
          <Flex gap={8} alignItems="center">
            {/* Desktop View - Horizontal Navigation */}
            <Text
              as={NavLink}
              to="/home"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              Home
            </Text>
            <Menu>
              <MenuButton
                as={Text}
                _hover={{
                  borderBottom: `3px solid ${THEME_COLOR}`,
                  cursor: "pointer",
                }}
                _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
                onClick={toggleDropdown}
              >
                <Flex fontFamily={FONT_FAMILY} fontWeight="bold">
                  Services
                  <Box as="span" ml={2}>
                    {<ChevronDownIcon />}
                  </Box>
                </Flex>
              </MenuButton>
              <MenuList w={"35vw"} p={2} border={`2px solid ${THEME_COLOR}`}>
                <Flex justifyContent={"space-between"}>
                  <Box>
                    {SERVICES.slice(0, SERVICES.length / 2).map(
                      (service, index) => (
                        <MenuItem
                          as={NavLink}
                          to={`/service/${service.title}`}
                          onClick={onServiceClick}
                          fontFamily={FONT_FAMILY}
                          key={index.toString() + "Service"}
                        >
                          {service.title}
                        </MenuItem>
                      )
                    )}
                  </Box>
                  <Box borderRight={`2px solid ${THEME_COLOR}`}></Box>
                  <Box>
                    {SERVICES.slice(SERVICES.length / 2).map(
                      (service, index) => (
                        <MenuItem
                          as={NavLink}
                          to={`/service/${service.title}`}
                          onClick={onServiceClick}
                          fontFamily={FONT_FAMILY}
                          key={index.toString() + "Service"}
                        >
                          {service.title}
                        </MenuItem>
                      )
                    )}
                  </Box>
                </Flex>
              </MenuList>
            </Menu>
            <Text
              as={NavLink}
              to="/about"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              About
            </Text>
            <Text
              as={NavLink}
              to="/ceo"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              CEO
            </Text>
            <Text
              as={NavLink}
              to="/sessions"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              Sessions
            </Text>
            <Text
              as={NavLink}
              to="https://asklaxman.wordpress.com/"
              target="__blank"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              Blog
            </Text>
            <Text
              as={NavLink}
              to="/vlog"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              Vlog
            </Text>
            <Text
              as={NavLink}
              to="/contact"
              _hover={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              _activeLink={{ borderBottom: `3px solid ${THEME_COLOR}` }}
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
            >
              Contact
            </Text>
            <Button
              as={NavLink}
              to={"https://www.ifaplanet.com/form_login.php"}
              target="__blank"
              fontFamily={FONT_FAMILY}
              fontWeight="bold"
              fontSize={"14px"}
              mr={10}
              letterSpacing={"1px"}
              // gap={"2px"}
              bg={"transparent"}
              border={`2px solid ${THEME_COLOR}`}
              borderRadius={"50px 50px"}
              color={THEME_COLOR}
              _hover={{ background: THEME_COLOR, color: "white" }}
            >
              Client Login
            </Button>
          </Flex>
        )}
      </>
    );
  };

  return (
    <Box
      bg={"white"}
      py={"10px"}
      position="fixed"
      width={"100%"}
      zIndex={10}
      color={THEME_COLOR}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={{ base: "20px", md: "40px" }}
      >
        <Flex
          color={"white"}
          fontSize={"24px"}
          width={"100%"}
          as={NavLink}
          to={"/"}
          alignItems={"center"}
        >
          <Box>
            <Image src={"/images/logo_1.png"} width={"40px"} />
          </Box>
          <Box
            marginLeft={"1%"}
            color={THEME_COLOR}
            fontWeight={"bold"}
            fontFamily={FONT_FAMILY}
            mt={1}
          >
            Laksh Investments
          </Box>
        </Flex>

        {/* Desktop Menu */}
        <HStack
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          spacing={8}
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
        >
          {navLinks()}
        </HStack>

        {/* Mobile Menu Text */}
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          sx={{
            backgroundColor: "white",
            color: { TEXT_THEME_COLOR },
            border: `2px solid ${TEXT_THEME_COLOR}`,
          }}
        />
      </Flex>

      {/* Mobile Menu */}
      {isOpen && (
        <Box
          position="absolute"
          top="60px" // Adjust this value to position the menu right below the navbar
          left="0"
          right="0"
          bg="white"
          shadow="md"
          p={4}
          zIndex={20}
          display={{ md: "none" }}
          className="mobileNav"
        >
          <Stack as="nav" spacing={4}>
            {navLinks()}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
