import { Container, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { ABOUT_IMAGES, THEME_COLOR } from "../../Constant";
import { ImageGallery } from "../../Utils";
import { AboutHome, AboutUs, OurMission } from ".";

export const About: React.FC = () => {
  const bgColor = useColorModeValue("#e9e9e9", "#141414");

  return (
    <>
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
      >
        <AboutHome />
      </Container>

      <Container maxW={"100vw"} py={{ base: 10, md: 20 }} bg={THEME_COLOR}>
        <AboutUs />
      </Container>

      <Container maxW="100vw" px={{ base: 5, md: 32 }}>
        <OurMission />
      </Container>

      <Container maxW={"100vw"} py={{ base: 10, md: 20 }} bg={bgColor}>
        <ImageGallery
          title="Memorable Moments"
          data={ABOUT_IMAGES}
          back={"white"}
        />
      </Container>
    </>
  );
};

export default About;
