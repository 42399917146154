import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { UseInView } from "../../Utils";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { BsCheckCircle } from "react-icons/bs";

export const OurMission: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      alignItems="center"
      justifyContent="space-between"
      py={{ base: 10, md: 20 }}
      ref={elementRef}
    >
      <Box
        flexBasis="66%"
        p={5}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Heading
          as="h5"
          size="lg"
          mb={4}
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
          textAlign={{ base: "center", md: "left" }}
        >
          Our Mission
        </Heading>
        <Flex mb={4} justifyContent={{ base: "center", md: "start" }}>
          <Image
            src={"/images/Testimonial.png"}
            filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
          />
        </Flex>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          Our mission is to make youngsters "Financially Free". We believe that
          Everyone should be Rich in Four aspects:
        </Text>
        <List spacing={2} fontFamily={FONT_FAMILY}>
          <ListItem>
            <HStack spacing={2}>
              <BsCheckCircle />
              <span>Rich in Nature</span>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack spacing={2}>
              <BsCheckCircle />
              <span>Rich in Relationship</span>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack spacing={2}>
              <BsCheckCircle />
              <span>Rich in Knowledge</span>
            </HStack>
          </ListItem>
          <ListItem>
            <HStack spacing={2}>
              <BsCheckCircle />
              <span>And of course Rich in Money</span>
            </HStack>
          </ListItem>
        </List>
      </Box>
      <Box
        flexBasis="33%"
        bg={THEME_COLOR}
        overflow={"hidden"}
        p={5}
        borderTopRightRadius={"120px"}
        borderBottomLeftRadius={"120px"}
        boxShadow={"0px 4px 4px 0px rgba(0,0,0,0.8)"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Image src="/images/growth.png" alt="Our Mission" />
      </Box>
    </Flex>
  );
};

export default OurMission;
