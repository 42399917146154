import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Template from "./Template";
import ScrollToTop from "./ScrollToTop";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ChakraProvider>
        <Template />
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;
