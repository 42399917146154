import { Container } from "@chakra-ui/react";
import React from "react";
import { SubscribeSection, VideoGrid } from ".";

export const Vlog: React.FC = () => {
  return (
    <>
      <Container maxW={"100vw"} p={0}>
        <SubscribeSection />
      </Container>

      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
      >
        <VideoGrid />
      </Container>
    </>
  );
};

export default Vlog;
