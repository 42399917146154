import { Box, Flex, Grid, Heading, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { FONT_FAMILY, THEME_COLOR } from "../Constant";
import { UseInView } from ".";

interface ImageGalleryProp {
  title: string;
  data: string[];
  back: string;
}

export const ImageGallery: React.FC<ImageGalleryProp> = ({
  title,
  data,
  back,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { isInView, elementRef } = UseInView();

  return (
    <Box ref={elementRef}>
      <Box
        textAlign={"center"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Heading
          as="h5"
          size="lg"
          mb={4}
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
        >
          {title}
        </Heading>
        <Flex justifyContent={"center"}>
          <Image
            src={"/images/Testimonial.png"}
            filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
          />
        </Flex>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={{ base: 1, md: 20 }}
        py={10}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Grid
          sx={{
            gridTemplateColumns: {
              base: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            },
          }}
          gap="1vh"
          p="1vh"
          bg={back}
        >
          {data.map((src, index) => (
            <Image
              key={index.toString() + "about"}
              src={src}
              alt={`gallery-image-${index}`}
              w="100%"
              aspectRatio="1 / 1"
              transition="all 0.3s ease"
              transform={hoveredIndex === index ? "scale(1.05)" : "scale(1)"}
              opacity={
                hoveredIndex === null || hoveredIndex === index ? 1 : 0.5
              }
              filter={
                hoveredIndex === null || hoveredIndex === index
                  ? "grayscale(0%)"
                  : "grayscale(70%)"
              }
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ImageGallery;
