import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { UseInView } from "../../Utils";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";

export const AboutHome: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      alignItems="center"
      textAlign={"justify"}
      ref={elementRef}
    >
      <Box
        w={{ base: "100%", md: "40%" }}
        p={5}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Image
          src="/images/about.png"
          height={"60vh"}
          width={"100%"}
          boxShadow={"0px 4px 4px 0px rgba(0,0,0,0.8)"}
        />
      </Box>
      <Box
        w={{ base: "100%", md: "60%" }}
        p={5}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Heading
          as="h5"
          size="lg"
          mb={4}
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
          textAlign={{ base: "center", md: "left" }}
        >
          Welcome to Laksh Investments!
        </Heading>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          Established in June 2022, Laksh Investments has quickly emerged as a
          trusted name in the financial services industry. With a strong
          presence spanning over six states, we are committed to delivering
          exceptional financial solutions to our diverse clientele.
        </Text>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          At Laksh Investments, we understand that each client has unique
          financial aspirations and challenges. Our team of experienced
          professionals is dedicated to providing personalized services that
          cater to your specific needs. Whether you're an individual looking to
          grow your wealth or a business seeking strategic financial planning,
          we have the expertise and resources to help you succeed.
        </Text>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          With a client base of more than 120 satisfied customers, we take pride
          in our track record of delivering results and building long-lasting
          relationships based on trust and integrity. Our comprehensive range of
          services includes investment management, tax planning, retirement
          planning, insurance solutions, and more, designed to empower you to
          make informed financial decisions.
        </Text>
        <Text fontFamily={FONT_FAMILY}>
          Join us on your journey towards financial success. Discover the
          difference with Laksh Investments today.
        </Text>
      </Box>
    </Flex>
  );
};

export default AboutHome;
