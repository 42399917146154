import { Container } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY } from "../../Constant";
import { Clients, Description, Landing, Services, Testimonial } from ".";

export const Home: React.FC = () => {
  return (
    <>
      {/* Landing Section */}
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
      >
        <Landing />
      </Container>

      {/* Description Section */}
      <Container maxW={"100vw"} p={0}>
        <Description />
      </Container>

      {/* Services Section */}
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
        fontFamily={FONT_FAMILY}
      >
        <Services />
      </Container>

      {/* Testimonial Section */}
      <Container maxW={"100vw"} p={0} fontFamily={FONT_FAMILY}>
        <Testimonial />
      </Container>

      {/* Clients Section */}
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
        fontFamily={FONT_FAMILY}
      >
        <Clients />
      </Container>
    </>
  );
};

export default Home;
