import { Box, Heading, Text, Button, Container } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FONT_FAMILY, THEME_COLOR } from "../Constant";

const NotFound = () => {
  return (
    <Container maxW="100vw" px={{ base: 5, md: 32 }} py={{ base: 10, md: 20 }}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          as="h1"
          size="4xl"
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2} fontFamily={FONT_FAMILY}>
          Page Not Found
        </Text>
        <Text color="gray.500" mb={6} fontFamily={FONT_FAMILY}>
          The page you're looking for does not seem to exist.
        </Text>

        <Link to="/">
          <Button
            letterSpacing={"1px"}
            gap={"5px"}
            bg={"transparent"}
            border={`2px solid ${THEME_COLOR}`}
            borderRadius={"50px 50px"}
            color={THEME_COLOR}
            _hover={{ background: THEME_COLOR, color: "white" }}
            w="8rem"
            fontFamily={FONT_FAMILY}
            fontSize={"14px"}
          >
            Go to Home
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default NotFound;
