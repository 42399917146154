import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { ClientsData, FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { useNavigate } from "react-router-dom";
import { UseInView } from "../../Utils";

export const Clients: React.FC = () => {
  const navigate = useNavigate();
  const { isInView, elementRef } = UseInView();

  // Utility function to convert hex to RGB
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  // Utility function to convert RGB to hex
  const rgbToHex = (r: number, g: number, b: number) => {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  };

  // Function to interpolate between two values
  const interpolate = (start: number, end: number, factor: number) => {
    return Math.round(start + (end - start) * factor);
  };

  // Function to generate lighter shades of a color
  const generateColorShades = (baseColor: string, numShades: number) => {
    const { r: baseR, g: baseG, b: baseB } = hexToRgb(baseColor);
    const targetR = 255; // target color (white)
    const targetG = 255;
    const targetB = 255;

    const shades: string[] = [];

    for (let i = 0; i < numShades; i++) {
      const factor = i / (numShades - 1); // Progress from 0 to 1
      const r = interpolate(baseR, targetR, factor);
      const g = interpolate(baseG, targetG, factor);
      const b = interpolate(baseB, targetB, factor);

      shades.push(rgbToHex(r, g, b));
    }

    return shades;
  };

  const colors = generateColorShades("#0c356a", ClientsData.datasets.length);

  const handleContact = () => {
    navigate(`/contact`);
  };

  const chartOptions = {
    chart: {
      type: "pie",
      events: {
        // Add text after the chart is rendered
        render: function (this: Highcharts.Chart) {
          const chart = this as any;
          const centerX = chart.plotWidth / 2 + chart.plotLeft;
          const centerY = chart.plotHeight / 2 + chart.plotTop;

          // Remove existing text if already rendered
          if (chart.customCenterText) {
            chart.customCenterText.destroy();
          }

          // Add text in the center
          chart.customCenterText = chart.renderer
            .text(
              `<p>160+ <br> ${chart.series[0].name}</p>`, // Text you want to display
              centerX,
              centerY
            )
            .attr({
              align: "center",

              verticalAlign: "middle",
              zIndex: 5, // To make sure text is above the chart
            })
            .css({
              fontSize: "28px",
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
            })
            .add();
          chart.customCenterText.attr({
            x: centerX - chart.customCenterText.getBBox().width / 2 + 50,
            y: centerY + chart.customCenterText.getBBox().height / 4 - 15,
          });
        },
      },
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        size: "100%",
        innerSize: "60%",
        dataLabels: {
          enabled: true,
          crop: false,
          distance: "-20%",
          style: {
            fontWeight: "bold",
            fontSize: "16px",
          },
          connectorWidth: 0,
        },
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipOptions) {
        const tooltip = this as any;
        return (
          tooltip.key +
          "<br>" +
          tooltip.point.custom +
          "</b> : <b>" +
          tooltip.y +
          "</b>"
        );
      } as any,
    },
    series: [
      {
        name: ClientsData.labels[0],
        data: ClientsData.datasets.map((value, index) => {
          return {
            name: value.label,
            y: value.data[0],
            custom: value.tooltip,
            color: colors[index],
          };
        }),
      },
    ],
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justifyContent={"center"}
      alignItems={"center"}
      ref={elementRef}
    >
      <Box
        w={{ base: "100%", md: "50%" }}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Heading
          as="h5"
          size="lg"
          mb={4}
          color={THEME_COLOR}
          fontFamily={FONT_FAMILY}
          textAlign={{ base: "center", md: "left" }}
        >
          Empowering Your Financial Future
        </Heading>
        <Flex mb={4} justifyContent={{ base: "center", md: "start" }}>
          <Image
            src={"/images/Testimonial.png"}
            filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
          />
        </Flex>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          At <b>Laksh Investments</b>, we are committed to helping you achieve
          financial clarity and success. Whether you're looking to grow your
          investments, manage debt, or plan for the future, our expert advice is
          tailored to your unique goals.
        </Text>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          We offer personalized, transparent, and actionable guidance, ensuring
          you make informed decisions at every step. Our clients trust us for
          reliable financial strategies that lead to lasting results.
        </Text>
        <Text mb={4} fontFamily={FONT_FAMILY}>
          Let us be your partner on the path to financial freedom.
          <Button
            letterSpacing={"1px"}
            gap={"5px"}
            bg={"transparent"}
            border={`2px solid ${THEME_COLOR}`}
            borderRadius={"50px 50px"}
            color={THEME_COLOR}
            ml={1}
            _hover={{ background: THEME_COLOR, color: "white" }}
            // w="8rem"
            onClick={handleContact}
            fontFamily={FONT_FAMILY}
            fontSize={"14px"}
          >
            Connect with us
          </Button>{" "}
          today and take control of your financial future with confidence.
        </Text>
      </Box>
      <Box
        w={{ base: "100%", md: "50%" }}
        display={"flex"}
        justifyContent={"center"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Box>
    </Flex>
  );
};

export default Clients;
