import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, THEME_COLOR } from "../Constant";
import { NavLink } from "react-router-dom";
import Social from "../Body/Contact/Social";
import { BsFillMapFill } from "react-icons/bs";

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const footer = [
    "Home",
    "About",
    "CEO",
    "Vlog",
    "Blog",
    "Sessions",
    "Contact",
  ];

  return (
    <Container maxW="100vw" p={0}>
      <Flex
        bg={THEME_COLOR}
        color="white"
        p={{ base: 5, lg: 10 }} // Responsive padding for smaller and larger screens
        py={{ base: 5, lg: 20 }}
        textAlign="center"
        mt="auto"
        borderTop="1px solid white"
        flexDirection={{ base: "column", md: "row" }} // Stack on smaller screens
        alignItems={{ base: "center", md: "flex-start" }} // Center on small screens, align left on medium+
        borderBottom={"1px solid white"}
      >
        {/* Left Section */}
        <Box w={{ base: "100%", md: "50%" }} mb={{ base: 6, md: 0 }}>
          {" "}
          {/* Add margin bottom for spacing on small screens */}
          <Flex alignItems="center" gap={5} textAlign="left">
            <Box>
              <Image
                src="/images/footerlogo.png"
                height={{ base: "60px", md: "80px" }}
              />{" "}
              {/* Responsive logo size */}
            </Box>
            <Box>
              <Text
                fontFamily={FONT_FAMILY}
                fontSize={{ base: "1.5rem", md: "2rem" }} // Smaller text on small screens
                fontWeight="bold"
              >
                Laksh Investments
              </Text>
              <Text
                fontFamily={FONT_FAMILY}
                fontSize={{ base: "1rem", md: "1.25rem" }}
              >
                {" "}
                {/* Smaller subtext */}
                It's all about planning
              </Text>
            </Box>
          </Flex>
        </Box>

        {/* Right Section */}
        <Box w={{ base: "100%", md: "50%" }}>
          <Flex direction={{ base: "column", md: "row" }} alignItems="center">
            <Flex
              w={{ base: "100%", md: "50%" }}
              textAlign={{ base: "center", md: "left" }}
              justifyContent={"space-between"}
              gap={5}
              direction={{ base: "column", md: "row" }}
            >
              {footer.map((item, index) => {
                return (
                  <Text
                    key={index.toString() + "item"}
                    fontFamily={FONT_FAMILY}
                    fontSize={{ base: "1rem", md: "1.2rem" }}
                    as={NavLink}
                    to={
                      item.toLowerCase() === "blog"
                        ? "https://asklaxman.wordpress.com/"
                        : `/${item.toLowerCase()}`
                    }
                    _hover={{
                      color: "yellow",
                    }}
                    _activeLink={{
                      color: "yellow",
                    }}
                    target={item.toLowerCase() === "blog" ? "__blank" : ""}
                  >
                    {" "}
                    {item}
                  </Text>
                );
              })}
            </Flex>
            <Box
              w={{ base: "100%", md: "50%" }}
              textAlign="left"
              mt={{ base: 4, md: 0 }}
            >
              {" "}
            </Box>
          </Flex>
          <Flex direction={"column"}>
            <Flex py={10} alignItems={"center"}>
              <Text mr={2}>
                <BsFillMapFill />
              </Text>

              <Text
                fontFamily={FONT_FAMILY}
                fontSize={{ base: "1rem", md: "1rem" }}
                textAlign={"left"}
              >
                {
                  "404, Shri Sai Swami Apt, N.R. Nagar, Station Road, Diva (west), Thane - 400612, India"
                }
              </Text>
            </Flex>
            <Flex justifyContent={{ base: "center", md: "left" }}>
              <Social />
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Flex
        bg={THEME_COLOR}
        color="white"
        py={4}
        mt="auto"
        w={"100%"}
        justifyContent={"center"}
      >
        <Text textAlign="center">
          Copyright © {currentYear} Laksh Investments | Designed and Developed
          by{" "}
          <Text
            as={NavLink}
            to={"https://ashutoshkanojiya.in/"}
            target="__blank"
            _hover={{
              borderBottom: `3px solid white`,
              cursor: "pointer",
            }}
          >
            Ashutosh Kanojiya
          </Text>
        </Text>
      </Flex>
    </Container>
  );
};

export default Footer;
