import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { NavLink } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { UseInView } from "../../Utils";

export const SubscribeSection: React.FC = () => {
  const { isInView, elementRef } = UseInView();

  return (
    <Flex
      bg={THEME_COLOR}
      backgroundImage="/images/home_back.jpg"
      backgroundPosition="center"
      backgroundSize="cover"
      flexDirection={{ base: "column", md: "row" }} // Stack on small screens, row on medium and above
      ref={elementRef}
    >
      <Flex
        bg={"rgba(0,0,0,0.5)"}
        py={{ base: 10, md: 20 }}
        px={{ base: 6, md: 20 }} // Adjust padding for smaller screens
        alignItems={"center"}
        flexDirection={{ base: "column", md: "row" }} // Align content properly on small screens
        textAlign={{ base: "center", md: "left" }} // Center text on small screens
      >
        <Box
          width={{ base: "100%", md: "30%" }}
          justifyContent={"center"}
          display={"flex"}
          mb={{ base: 6, md: 0 }} // Add margin-bottom for small screens
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Image
            src="/images/logo.jpg"
            width={{ base: "60%", md: "50%" }} // Adjust image width on small screens
            borderRadius={"full"}
          />
        </Box>
        <Box
          width={{ base: "100%", md: "70%" }}
          color={"white"}
          fontFamily={FONT_FAMILY}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Text
            fontSize={{ base: "2xl", md: "3rem" }}
            fontWeight="bold"
            fontFamily={FONT_FAMILY}
          >
            Laksh Investments
          </Text>
          <Text
            fontStyle={"italic"}
            mb={4}
            fontSize={{ base: "sm", md: "lg" }}
            fontFamily={FONT_FAMILY}
          >
            "Laksh Investments is a professional financial service provider with
            a proven track record. Laksh Investments helps you to reach your
            desired goals with a complete financial planning for you. Laksh
            Investments is a one-stop solution for all your needs."
          </Text>
          <Button
            letterSpacing={"1px"}
            gap={"5px"}
            bg={"transparent"}
            border={"2px solid white"}
            borderRadius={"50px 50px"}
            color={"white"}
            _hover={{ background: `${THEME_COLOR}` }}
            as={NavLink}
            to="https://www.youtube.com/@lakshinvestments/"
            target="_blank"
            fontFamily={FONT_FAMILY}
          >
            <BsBell />
            Subscribe
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubscribeSection;
