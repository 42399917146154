import { Box } from "@chakra-ui/react";
import React from "react";
import { Navbar } from "./app/Navbar";
import { Footer } from "./app/Footer";
import Pages from "./Pages";

function Template() {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar />
      <Box flex="1" marginTop={"30px"}>
        <Pages />
      </Box>

      <Footer />
    </Box>
  );
}

export default Template;
