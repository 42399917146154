import React from "react";
import { Container, useColorModeValue } from "@chakra-ui/react";
import { CeoMiddle, CeoTop } from ".";
import { AWARDS, THEME_COLOR } from "../../Constant";
import { ImageGallery } from "../../Utils";

export const CEO: React.FC = () => {
  const bgColor = useColorModeValue("#e9e9e9", "#141414");
  return (
    <>
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        pt={{ base: 10, md: 20 }}
        pb={8}
      >
        <CeoTop />
      </Container>

      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        pb={{ base: 10, md: 20 }}
      >
        <CeoMiddle />
      </Container>

      <Container maxW={"100vw"} py={{ base: 10, md: 20 }} bg={bgColor}>
        <ImageGallery
          title="Few Awards and Honors"
          data={AWARDS}
          back={THEME_COLOR}
        />
      </Container>
    </>
  );
};

export default CEO;
