import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import "animate.css";
import { UseInView } from "../../Utils";

export const Landing: React.FC = () => {
  const { isInView, elementRef } = UseInView();

  return (
    <Flex
      align="center"
      className="home"
      direction={{ base: "column", md: "row" }}
      ref={elementRef}
    >
      <Box
        flex={{ base: "1 1 auto", md: "7" }}
        mb={{ base: 8, md: 0 }}
        p={{ base: 0, md: 8 }}
      >
        <Image
          src="/images/home.png"
          alt="Home"
          width="100%"
          boxShadow={{ base: "none", md: "0px 4px 4px 0px rgba(0,0,0,0.5)" }}
          borderTopLeftRadius={{ base: "0px", md: "120px" }}
          borderBottomRightRadius={{ base: "0px", md: "120px" }}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        />
      </Box>
      <Box
        flex={{ base: "1 1 auto", md: "5" }}
        // textAlign={{ base: "center", md: "left" }}
        textAlign={"center"}
      >
        <Heading
          as="h1"
          textTransform="uppercase"
          fontSize={{ base: "2xl", md: "3.5rem" }}
          fontFamily={FONT_FAMILY}
          color={THEME_COLOR}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          Laksh Investments
        </Heading>
        <Flex mb={4} justifyContent={"center"}>
          <Image
            src={"/images/Testimonial.png"}
            filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
            w="250px"
            className={isInView ? "animate__animated animate__zoomIn" : ""}
          />
        </Flex>
        <Text
          fontStyle="italic"
          fontSize={{ base: "md", md: "2xl" }}
          fontFamily={FONT_FAMILY}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          It's all about planning
        </Text>
      </Box>
    </Flex>
  );
};

export default Landing;
