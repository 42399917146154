import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { UseInView } from "../../Utils";
import { FONT_FAMILY } from "../../Constant";

export const AboutUs: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  return (
    <>
      <Flex
        direction={{ base: "column", md: "row" }}
        textAlign={"center"}
        color={"white"}
        px={{ base: 0, md: 28 }}
        ref={elementRef}
      >
        <Box
          w={{ base: "100%", md: "50%" }}
          p={5}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Heading as="h5" size="lg" mb={4} fontFamily={FONT_FAMILY}>
            Who we are?
          </Heading>
          <Flex mb={4} justifyContent={"center"}>
            <Image src={"/images/Testimonial.png"} />
          </Flex>
          <Text fontFamily={FONT_FAMILY}>
            We are called transformers whose focus is to bring a
            transformational change for Middle-income and upper-middle-income
            people by making them wealthy through proper planning.
          </Text>
        </Box>
        <Box
          w={{ base: "100%", md: "50%" }}
          p={5}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Heading as="h5" size="lg" mb={4} fontFamily={FONT_FAMILY}>
            What we do?
          </Heading>
          <Flex mb={4} justifyContent={"center"}>
            <Image src={"/images/Testimonial.png"} />
          </Flex>
          <Text fontFamily={FONT_FAMILY}>
            As the name itself says LAKSH INVESTMENTS. So, we focus and identify
            your LAKSHYA (Goals) and create a solid Investment plan which helps
            you to achieve your goals and make you wealthy.
          </Text>
        </Box>
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        textAlign={"center"}
        color={"white"}
        justifyContent={"center"}
        mt={"50px"}
        ref={elementRef}
      >
        <Box className={isInView ? "animate__animated animate__zoomIn" : ""}>
          <Heading
            as="h5"
            size="lg"
            mb={4}
            color={"white"}
            fontFamily={FONT_FAMILY}
          >
            What makes us different?
          </Heading>
          <Flex mb={4} justifyContent={"center"}>
            <Image src={"/images/Testimonial.png"} />
          </Flex>
          <Text color={"white"} fontFamily={FONT_FAMILY}>
            We always give first priority to the client's benefit. Going the
            extra mile when it comes to helping our clients is our USP.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default AboutUs;
