import { HStack, Icon, Link } from "@chakra-ui/react";
import React from "react";
import {
  FaLinkedin,
  FaFacebookF,
  FaTwitter,
  FaWordpress,
  FaInstagram,
} from "react-icons/fa";

const Social: React.FC = () => {
  return (
    <HStack spacing={4}>
      <Link
        href="https://www.linkedin.com/in/laxman-agrawal-3713a7133"
        isExternal
      >
        <Icon as={FaLinkedin} boxSize={"1.5rem"} />
      </Link>
      <Link href="https://www.facebook.com/laxman.agrawal.7" isExternal>
        <Icon as={FaFacebookF} boxSize={"1.5rem"} />
      </Link>
      <Link
        href="https://twitter.com/LaxmanSAgrawal?t=QvqvBICoKzm9M-PnttZsUw&#038;s=08"
        isExternal
      >
        <Icon as={FaTwitter} boxSize={"1.5rem"} />
      </Link>
      <Link href="https://asklaxman.wordpress.com/" isExternal>
        <Icon as={FaWordpress} boxSize={"1.5rem"} />
      </Link>
      <Link
        href="https://instagram.com/laksh.investments?igshid=YmMyMTA2M2Y="
        isExternal
      >
        <Icon as={FaInstagram} boxSize={"1.5rem"} />
      </Link>
    </HStack>
  );
};

export default Social;
