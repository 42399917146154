import { Box, Flex, Heading, IconButton, Image, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { CLIENTS, FONT_FAMILY } from "../../Constant";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { UseInView } from "../../Utils";

export const Testimonial: React.FC = () => {
  const { isInView, elementRef } = UseInView();

  const clients = useMemo(() => {
    const clientData = CLIENTS.map((value) => ({
      img: value[0],
      name: value[1],
      designation: value[2],
      comment: value[3],
    }));
    return clientData;
  }, []);

  const [current, setCurrent] = useState(0);

  const nextSlide = () => {
    setCurrent((prev) => (prev === clients.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? clients.length - 1 : prev - 1));
  };

  return (
    <Box
      backgroundImage="url('https://i.ibb.co/PTJDkgb/testimonials.jpg')"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundAttachment={"fixed"}
      py={{ base: 10, md: 20 }}
      textAlign="center"
      position="relative"
      ref={elementRef}
    >
      <Heading
        as="h5"
        size="lg"
        mb={4}
        color={"white"}
        fontFamily={FONT_FAMILY}
        textAlign={"center"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        Testimonial
      </Heading>

      <Flex
        mb={10}
        justifyContent={"center"}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <Image src={"/images/Testimonial.png"} />
      </Flex>

      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        fontFamily={FONT_FAMILY}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        <IconButton
          aria-label="Previous"
          icon={<ArrowBackIcon />}
          position="absolute"
          left="5%"
          top="50%"
          transform="translateY(-50%)"
          onClick={prevSlide}
          borderRadius="full"
          bg="transparent"
          border="2px solid white"
          color={"white"}
          _hover={{ bg: "white", color: "black" }}
        />

        <Box textAlign="center" width="60%">
          <Image
            src={clients[current].img}
            borderRadius="full"
            boxSize="136px"
            margin="auto"
            boxShadow="xl"
            mb={4}
          />
          <Text color="white" fontSize="xl" mb={4} fontFamily={FONT_FAMILY}>
            {clients[current].comment}
          </Text>
          <Heading color="white" fontSize="lg" mb={4} fontFamily={FONT_FAMILY}>
            {clients[current].name}
          </Heading>
          <Text
            color="white"
            fontSize="lg"
            fontStyle={"italic"}
            fontFamily={FONT_FAMILY}
          >
            ( {clients[current].designation} )
          </Text>
        </Box>

        <IconButton
          aria-label="Next"
          icon={<ArrowForwardIcon />}
          position="absolute"
          right="5%"
          top="50%"
          transform="translateY(-50%)"
          onClick={nextSlide}
          borderRadius="full"
          bg="transparent"
          border="2px solid white"
          color={"white"}
          _hover={{ bg: "white", color: "black" }}
        />
      </Flex>
      {/* Pagination Dots */}
      <Flex
        justify="center"
        mt={6}
        className={isInView ? "animate__animated animate__zoomIn" : ""}
      >
        {clients.map((_, index) => (
          <Box
            key={index.toString() + "Client"}
            h="10px"
            w="10px"
            borderRadius="50%"
            bg={current === index ? "gray" : "white"}
            mx={1}
            cursor="pointer"
            onClick={() => setCurrent(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Testimonial;
