import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { FONT_FAMILY, THEME_COLOR } from "../../Constant";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { UseInView } from "../../Utils";

interface ServiceProp {
  title: string;
  description: string[];
  imgSrc: string;
}

interface Props {
  services: ServiceProp[];
}

export const Service: React.FC<Props> = ({ services }) => {
  const { title } = useParams<{ title: string }>();
  const { isInView, elementRef } = UseInView();

  const service = services.find((service) => service.title === title);

  if (!service)
    return (
      <Text mt={"50px"} color={"black"}>
        Service not found
      </Text>
    );

  return (
    <Container maxW="100vw" px={{ base: 5, md: 32 }} py={{ base: 10, md: 20 }}>
      <Flex>
        <Text mr={1}>
          <ChevronLeftIcon boxSize={6} />
        </Text>
        <Text
          mb={4}
          as={NavLink}
          to={"/"}
          fontFamily={FONT_FAMILY}
          color={THEME_COLOR}
          fontSize={"20px"}
          fontWeight={"bold"}
        >
          Back
        </Text>
        <Text
          ml={1}
          fontFamily={FONT_FAMILY}
          color={THEME_COLOR}
          fontSize={"20px"}
          fontWeight={"bold"}
        >
          {" "}
          / {service.title}
        </Text>
      </Flex>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}
        ref={elementRef}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Image
            src={service.imgSrc}
            alt={service.title}
            height={"400px"}
            boxShadow={"0px 4px 4px 0px rgba(0,0,0,0.8)"}
          />
        </Box>

        <Box
          p="6"
          className={isInView ? "animate__animated animate__zoomIn" : ""}
        >
          <Text
            fontWeight="bold"
            fontSize="2xl"
            fontFamily={FONT_FAMILY}
            textAlign={"center"}
          >
            {service.title}
          </Text>
          {service.description.map((description, index) => (
            <Text
              key={index.toString() + "Description"}
              mt={4}
              fontFamily={FONT_FAMILY}
              textAlign={"justify"}
            >
              {description}
            </Text>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Service;
