import { Route, Routes } from "react-router-dom";
import { About, CEO, Contact, Home, Service, Sessions, Vlog } from "./app/Body";
import { SERVICES } from "./app/Constant";
import NotFound from "./app/Error/NotFound";

export const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/service/:title" element={<Service services={SERVICES} />} />
      <Route path="/ceo" element={<CEO />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/vlog" element={<Vlog />} />
      <Route path="/sessions" element={<Sessions />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Pages;
