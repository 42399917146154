import React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { FaAddressBook, FaEnvelope, FaPhone } from "react-icons/fa";
import {
  ADDRESS,
  EMAILS,
  FONT_FAMILY,
  MOBILE_NUMBERS,
  THEME_COLOR,
} from "../../Constant";
import ContactUsForm from "./ContactUsForm";
import Social from "./Social";
import { UseInView } from "../../Utils";

export const Contact: React.FC = () => {
  const { isInView, elementRef } = UseInView();
  return (
    <>
      <Container
        maxW="100vw"
        px={{ base: 5, md: 32 }}
        py={{ base: 10, md: 20 }}
        fontFamily={FONT_FAMILY}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems="center"
          gap={6}
          ref={elementRef}
        >
          <Box
            w={{ base: "100%", md: "50%" }}
            color={THEME_COLOR}
            fontFamily={FONT_FAMILY}
            className={isInView ? "animate__animated animate__zoomIn" : ""}
          >
            <Heading
              as="h5"
              size="lg"
              mb={4}
              color={THEME_COLOR}
              fontFamily={FONT_FAMILY}
              textAlign={{ base: "center", md: "left" }}
            >
              Connect With Us
            </Heading>
            <Flex mb={4} justifyContent={{ base: "center", md: "start" }}>
              <Image
                src={"/images/Testimonial.png"}
                filter="invert(80%) sepia(80%) saturate(300%) hue-rotate(180deg)"
              />
            </Flex>
            <VStack align="start" spacing={4} mt={4}>
              <Box>
                <Heading
                  as="h5"
                  size="sm"
                  mb={"2%"}
                  display={"flex"}
                  fontFamily={FONT_FAMILY}
                >
                  <Icon as={FaAddressBook} />{" "}
                  <Text mt={"-2px"} ml={"5px"} fontFamily={FONT_FAMILY}>
                    Address
                  </Text>
                </Heading>
                <Text fontFamily={FONT_FAMILY}>{ADDRESS}</Text>
              </Box>
              <Box>
                <Heading as="h5" size="sm" mb={"2%"} display={"flex"}>
                  <Icon as={FaEnvelope} />
                  <Text mt={"-2px"} ml={"5px"} fontFamily={FONT_FAMILY}>
                    Email
                  </Text>
                </Heading>
                {EMAILS.map((value: string, index: number) => {
                  return (
                    <Text key={index.toString()} fontFamily={FONT_FAMILY}>
                      {value}
                    </Text>
                  );
                })}
              </Box>
              <Box>
                <Heading as="h5" size="sm" mb={"2%"} display={"flex"}>
                  <Icon as={FaPhone} />
                  <Text mt={"-2px"} ml={"5px"} fontFamily={FONT_FAMILY}>
                    Call
                  </Text>
                </Heading>
                {MOBILE_NUMBERS.map((value: string, index: number) => {
                  return (
                    <Text key={index.toString()} fontFamily={FONT_FAMILY}>
                      {value}
                    </Text>
                  );
                })}
              </Box>
              <Box>
                <Heading as="h5" size="sm" mb={2} fontFamily={FONT_FAMILY}>
                  Our Social Media Handle
                </Heading>
                <Social />
              </Box>
            </VStack>
          </Box>

          <Box
            w={{ base: "100%", md: "50%" }}
            className={isInView ? "animate__animated animate__zoomIn" : ""}
          >
            <ContactUsForm />
          </Box>
        </Flex>
      </Container>

      <Box w="100%" mt={4}>
        <Box fontFamily={FONT_FAMILY}>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.115386805237!2d73.04154567444722!3d19.19016194838016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf66b515abf5%3A0x7bb46fde5d5ecb15!2sLaksh%20Investments!5e0!3m2!1sen!2sus!4v1713111671433!5m2!1sen!2sus"
            width="100%"
            height="450px"
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            style={{ border: "none", fontFamily: `${FONT_FAMILY}` }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Contact;
